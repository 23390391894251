<template>
  <div class="page">
    <div class="creation-box">
      <div class="left" v-if="detail.length">
        <!-- 列表组件 -->
        <list :discoveryList="detail" :creation="false"></list>

        <div class="foot-page" v-if="total > 10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <div class="left" v-else>
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
      <!-- 右侧个人信息区 -->
      <div class="right">
        <div class="box">
          <div class="bottom">
            <img class="headPortrait" :src="userPhoto" />
            <span>{{ user.name ? user.name : "请登录" }}</span>
            <div class="group">
              <div class="left">
                <span>{{ user.fans }}</span>
                <span class="subTitle">粉丝</span>
              </div>
              <div class="line"></div>
              <div class="left">
                <span>{{ user.attention }}</span>
                <span class="subTitle">关注</span>
              </div>
            </div>
            <template v-if="userInfo.id != $route.query.id">
              <span v-if="isUserAttention" class="button2">已关注</span>
              <span v-else class="button" @click="attention">+关注</span>
            </template>
          </div>
        </div>
        <!-- 右侧二屏 -->
        <div class="box3">
          <div
            class="box3-group"
            :class="{ active: activeIndex == index }"
            v-for="(item, index) in user.profit"
            :key="index"
            @click="
              page = 0;
              getList(list[index].type, index);
            "
          >
            <img :src="list[index].img" />
            <!-- <span class="money">￥{{ item[0] }}</span> -->
            <span class="name">{{ list[index].name }}</span>
            <span>{{ item[1] }}</span>
          </div>
        </div>
        <!-- 右侧信息区 -->
        <messageTwo></messageTwo>
      </div>
    </div>
  </div>
</template>
<script>
import messageTwo from "@components/message/message";
import list from "@components/common/list";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    messageTwo,
    list,
  },
  data() {
    return {
      creation: [],
      creationList: null,
      list: [
        {
          img: require("@image/shuoshuo.png"),
          name: "发布说说",
          type: 1,
        },
        {
          img: require("@image/bingli.png"),
          name: "病例分享",
          type: 2,
        },
        {
          img: require("@image/shiping.png"),
          name: "学术视频",
          type: 3,
        },
        {
          img: require("@image/citiao.png"),
          name: "指南解析",
          type: 4,
        },
        {
          img: require("@image/lunwen.png"),
          name: "发布论文",
          type: 11,
        },
        {
          img: require("@image/wenda.png"),
          name: "发布问答",
          type: 5,
        },
      ],
      page: 0,
      limit: 10,
      total: 0,
      detail: "",
      type: "",
      user: {
        name: "",
        fans: 0,
        attention: 0,
        photo: "",
        profit: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
      },
      isUserAttention: false,
      activeIndex: "",
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    userPhoto() {
      return this.user.photo
        ? this.user.photo
        : this.$http.baseURL + "/static/images/web/1.png";
    },
  },
  created() {
    let token = localStorage.getItem("token") || "";
    if (token) {
      this.getList();
      this.isAttention();
    }
  },

  methods: {
    async handleSizeChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.getList();
      this.xin.isLoadding(false);
    },
    async handleCurrentChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.getList(this.type);
      this.xin.isLoadding(false);
    },
    // 发现详情
    async getList(type, index) {
      this.activeIndex = index;

      this.xin.isLoadding(true);
      let res = await this.$creationApi.creationList({
        user_id: this.$route.query.id,
        page: this.page,
        limit: this.limit,
        type: type ? type : "",
      });
      if (res.code == 200) {
        let data = res.data.discover.data || [];
        data.forEach((v) => {
          if (v.images) {
            v.images = JSON.parse(v.images);
          } else {
            v.images = [];
          }
        });
        this.detail = data;
        this.user = res.data.user;
        this.total = res.data.discover.total;
        this.type = type ? type : "";
      }
      this.xin.isLoadding();
    },
    //关注
    async attention() {
      let { data, code, message } = await this.$userApi.postDiscoverAttention({
        attention_id: this.$route.query.id,
      });
      if (code == 200) {
        this.$message.success(data);
        this.$router.go();
      } else {
        this.$message.error(message);
      }
    },
    //判断是否关注该主页用户
    async isAttention() {
      let res = await this.$userApi.postDiscoverIsAttention({
        attention_id: this.$route.query.id,
      });
      this.isUserAttention = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.active .name {
  color: $--themeColor;
}

.foot-page {
  height: 40px;
  text-align: center;
  width: 100%;
}
.button {
  background: $--themeColor;
  width: 220px;
  height: 44px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  margin-top: 10px;
}
.button2 {
  width: 220px;
  height: 44px;
  border-radius: 5px;
  color: #8f8f8f;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  margin-top: 10px;
}
.page {
  > .creation-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 30px;

    > .left {
      flex: 1;
      overflow: hidden;

      > .leftContent {
        display: flex;
        flex-direction: column;

        > .group-one {
          display: flex;
          flex-direction: row;
          margin-top: 23px;
          padding-bottom: 30px;
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 30px;

          > .group-one-box {
            position: relative;

            > .rectangular {
              position: absolute;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 79px;
              height: 26px;
              border-radius: 13px;
              opacity: 0.3;
              background: #000000;
              top: 164px;
              left: 181px;
              font-size: 12px;
              color: #ffffff;

              > img {
                width: 16px;
                height: 18px;
                object-fit: cover;
                margin-right: 4px;
              }
            }

            > .banner {
              width: 270px;
              height: 200px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          > .group-one-box2 {
            position: relative;

            > .rectangular2 {
              position: absolute;
              width: 50px;
              height: 26px;
              opacity: 0.3;
              background: #000000;
              border-radius: 13px;
              font-size: 14px;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
              left: 210px;
              top: 164px;
            }

            > .banner2 {
              width: 270px;
              height: 200px;
              object-fit: cover;
              border-radius: 10px;
            }

            > .banner5 {
              width: 270px;
              height: 223px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 20px;
            }
          }

          > .group-one-right {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-left: 20px;
            color: #8f8f8f;
            font-size: 18px;
            line-height: 30px;

            &:first-child {
              margin-left: 0;
            }

            > span {
              word-wrap: break-word;
              white-space: normal;
              word-break: break-all;
            }

            > .subTitle2 {
              font-size: 24px;
              color: #333333;
              line-height: 1.8;
              margin-bottom: 20px;
            }

            > .subTitle {
              font-size: 24px;
              color: #333333;
              overflow: hidden;
              line-height: 1.8;
              /*必须结合的属性,当内容溢出元素框时发生的事情*/
              text-overflow: ellipsis;
              /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
              display: -webkit-box;
              /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
              -webkit-line-clamp: 3;
              /*用来限制在一个块元素显示的文本的行数。*/
              -webkit-box-orient: vertical;
            }

            > .group-one-right-data {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 18px;
              color: #8f8f8f;
              margin-top: auto;

              > .data-button {
                font-size: 13px;
                color: $--themeColor;
                width: 100px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 26px;
              }

              > .data-audit {
                color: #e60012;
                margin-left: 15px;
                margin-right: 10px;
              }

              > img {
                width: 16px;
                height: 16px;
                object-fit: cover;
              }

              > .data-data {
                font-size: 18px;
                color: #8f8f8f;
                margin-left: 10px;

                > span {
                  margin-right: 16px;
                }
              }
            }
          }
        }

        > .talk {
          > .group-two {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            color: #8f8f8f;
            padding-bottom: 30px;
            box-sizing: border-box;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 30px;

            > .bottom {
              display: flex;
              flex-direction: row;
              margin-top: 15px;

              > .banner3 {
                width: 145px;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
                margin-right: 20px;
              }

              > div {
                display: flex;
                flex-direction: column;

                > .title {
                  font-size: 24px;
                  color: #333333;
                  margin-bottom: 10px;
                }

                > .subTitle {
                  line-height: 1.8;
                }

                > .group-two-bottom {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  font-size: 18px;
                  color: #8f8f8f;
                  margin-top: auto;

                  > .data-button {
                    font-size: 13px;
                    color: $--themeColor;
                    width: 100px;
                    height: 25px;
                    border: 1px solid $--themeColor;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 26px;
                  }

                  > span {
                    margin-right: 16px;
                  }
                }
              }
            }

            > .group-two-bottom {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 18px;
              color: #333333;
              margin-top: 19px;

              > .data-data {
                font-size: 18px;
                color: #8f8f8f;
                margin-left: 10px;

                > span {
                  margin-right: 16px;
                }
              }

              > .button {
                font-size: 13px;
                color: $--themeColor;
                width: 75px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 26px;
                margin-right: 10px;
              }
            }

            > .title {
              font-size: 24px;
              color: #333333;
              margin-bottom: 10px;
            }

            > .subTitle {
              line-height: 1.8;
            }
          }
        }

        > .group-one-right {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: 20px;
          color: #8f8f8f;
          font-size: 18px;
          line-height: 30px;
          padding-bottom: 30px;
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 30px;

          &:first-child {
            margin-left: 0;
          }

          > .subTitle2 {
            line-height: 1.8;
            margin-bottom: 4px;
          }

          > .subTitle {
            font-size: 24px;
            color: #333333;
            overflow: hidden;
            line-height: 1.8;
            /*必须结合的属性,当内容溢出元素框时发生的事情*/
            text-overflow: ellipsis;
            /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
            display: -webkit-box;
            /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
            -webkit-line-clamp: 3;
            /*用来限制在一个块元素显示的文本的行数。*/
            -webkit-box-orient: vertical;
          }

          > .data {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            > .data-button {
              font-size: 13px;
              color: $--themeColor;
              width: 100px;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 26px;
            }

            > span {
              margin-left: 10px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      > .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 294px;
        background: #f2f2f2;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 20px;

        > .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 264px;
          background: #ffffff;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;
          padding: 20px 22px;
          box-sizing: border-box;
          font-size: 20px;

          > .headPortrait {
            width: 60px;
            height: 60px;
            object-fit: cover;
            margin-bottom: 10px;
            border-radius: 50%;
          }

          > .group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;

            > .left {
              // cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;

              > .subTitle {
                color: #8f8f8f;
                font-size: 14px;
                margin-top: 8px;
              }
            }

            > .line {
              width: 2px;
              height: 30px;
              background: #f2f2f2;
              margin: 0 50px;
            }
          }
        }
      }

      > .box3 {
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
        width: 294px;
        background: #f2f2f2;
        margin-bottom: 20px;

        > .box3-group {
          &:last-child {
            margin-bottom: 0;
          }

          display: flex;
          flex-direction: row;
          align-items: center;
          color: #333333;
          font-size: 16px;
          margin-bottom: 23px;
          cursor: pointer;

          > img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            margin-right: 10px;
          }

          > span {
            flex: 1;
          }
        }
      }

      > .box2 {
        display: flex;
        flex-direction: column;
        width: 294px;
        background: #f2f2f2;
        padding: 0 15px;
        box-sizing: border-box;

        > .group {
          &:first-child {
            margin-top: 15px;
          }

          display: flex;
          flex-direction: row;
          margin-bottom: 15px;

          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }

          > .right {
            display: flex;
            flex-direction: column;
            font-size: 14px;

            > .title {
              color: #333333;
              line-height: 20px;
            }

            > .time {
              color: #8f8f8f;
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}
</style>
